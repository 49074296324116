import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';
import useDisclosure from '@hooks/useDisclosure';
import { Link } from 'react-router-dom';
import WorkflowStepper from './WorkflowStepper';

const drawerWidth = 320;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    ...(open ? openedMixin(theme) : closedMixin(theme)),
  },
  ...(open ? openedMixin(theme) : closedMixin(theme)),
}));

export default function WorkflowStepperWrapper({
  workflow,
  isWorkflowPaneOpen,
}) {
  const { isOpen, onToggle } = useDisclosure(isWorkflowPaneOpen ?? true);
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '100% !important',
        flexGrow: 1,
        '& .MuiDrawer-root': {
          position: 'relative',
        },
        '& .MuiPaper-root': {
          position: 'relative',
          width: '100%',
        },
      }}
      open={isOpen}
    >
      <DrawerHeader sx={{ justifyContent: 'space-between', px: 2 }}>
        {isOpen ? (
          <Typography
            sx={{ fontWeight: 700, color: 'grey.700', fontSize: '16px' }}
          >
            Workflow
          </Typography>
        ) : null}
        <IconButton onClick={onToggle}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Stack
        sx={{ height: '100vh' }}
        alignItems="center"
        justifyContent="flex-start"
      >
        {isOpen && (
          <Button
            component={Link}
            to="workflow"
            sx={{ textDecoration: 'none' }}
            variant="outlined"
          >
            <FormattedMessage id="Workflow.Edit.Button" defaultMessage="Edit" />
          </Button>
        )}
        <WorkflowStepper isOpen={isOpen} workflow={workflow} />
      </Stack>
    </Drawer>
  );
}
